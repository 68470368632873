import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageWrapperComponent } from './pages/page-wrapper/page-wrapper.component';
import { IndexPageComponent } from './pages/index-page/index-page.component';
import { HelpPageComponent } from './pages/help-page/help-page.component';
import { SubscriptionPageComponent } from './pages/subscription-page/subscription-page.component';
import { UserProfilePageComponent } from './pages/user-profile-page/user-profile-page.component';
import { UpdateUserPasswordPageComponent } from './pages/update-user-password-page/update-user-password-page.component';
import { UpdateUserEmailPageComponent } from './pages/update-user-email-page/update-user-email-page.component';
import { LoginPageComponent } from './pages/login-page/login-page.component';
import { OrderListPageComponent } from './pages/order-list-page/order-list-page.component';
import { AffiliatePageComponent } from './pages/affiliate-page/affiliate-page.component';
import { WithdrawPageComponent } from './pages/withdraw-page/withdraw-page.component';
import { RefundPolicyPageComponent } from './pages/refund-policy-page/refund-policy-page.component';
import { AboutUsPageComponent } from './pages/about-us-page/about-us-page.component';
import { ContactUsPageComponent } from './pages/contact-us-page/contact-us-page.component';
import { TermsConditionPageComponent } from './pages/terms-condition-page/terms-condition-page.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import { PrivacyPolicyPageComponent } from './pages/privacy-policy-page/privacy-policy-page.component';
import { ContactUsSendSuccessfulPageComponent } from './pages/contact-us-send-successful-page/contact-us-send-successful-page.component';
import { AffiliateAvertPageComponent } from './pages/affiliate-avert-page/affiliate-avert-page.component';
import { LoginOrSignUpCardComponent } from './components/login/login-or-sign-up-card/login-or-sign-up-card.component';
import { SetUpPasswordCardComponent } from './components/login/set-up-password-card/set-up-password-card.component';
import { LoginWithPasswordCardComponent } from './components/login/login-with-password-card/login-with-password-card.component';
import { LoginWithVerificationCardComponent } from './components/login/login-with-verification-card/login-with-verification-card.component';
import { EmailSignUpCardComponent } from './components/login/email-sign-up-card/email-sign-up-card.component';
import {
  RECAPTCHA_BASE_URL,
  RECAPTCHA_V3_SITE_KEY,
  RecaptchaV3Module,
} from 'ng-recaptcha';
import { environment } from 'src/environments/environment';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { AffiliateFormComponent } from './pages/affiliate-form/affiliate-form.component';
import { CompanyPageComponent } from './pages/company-page/company-page.component';
import { HelpTicketPageComponent } from './pages/help-ticket-page/help-ticket-page.component';

// startup界面
import { StartupScreenComponent } from './startup-screen/startup-screen.component'; // 确保导入了你的启动屏幕组件
// 路由守卫
import { accessGuard } from './guard/access.guard';
import {IptvTutorialPageComponent} from "./pages/iptv-tutorial-page/iptv-tutorial-page.component";



const routes: Routes = [
  { 
    path: 'startup', 
    pathMatch: 'full', 
    component: StartupScreenComponent // 作为应用的主入口点
  },
  {
    path: 'login',
    component: LoginPageComponent,
    canActivate:[accessGuard] ,
    children: [
      {
        path: '',
        component: LoginOrSignUpCardComponent,
        pathMatch: 'full',
      },
      {
        path: 'password/setup',
        component: SetUpPasswordCardComponent,
        pathMatch: 'full',
      },
      {
        path: 'password',
        component: LoginWithPasswordCardComponent,
        pathMatch: 'full',
      },
      {
        path: 'verification',
        component: LoginWithVerificationCardComponent,
        pathMatch: 'full',
      },
      {
        path: 'email/signup',
        component: EmailSignUpCardComponent,
        pathMatch: 'full',
      },
    ],
  },
  // {
  //   path: 'chatgpt-share-account',
  //   pathMatch: 'full',
  //   component: LandingPageComponent,
  //   canActivate:[accessGuard] 
  // },
  {
    path: '', 
    component: PageWrapperComponent,
    canActivate:[accessGuard] ,
    children: [
      {
        path: '',  
        pathMatch: 'full',
        component: IndexPageComponent,
      },
      {
        path: 'help',
        pathMatch: 'full',
        component: HelpPageComponent,
      },
      {
        path: 'help/ticket',
        pathMatch: 'full',
        component: HelpTicketPageComponent,
      },
      {
        path: 'subscription',
        pathMatch: 'full',
        component: SubscriptionPageComponent,
      },
      {
        path: 'user/profile',
        pathMatch: 'full',
        component: UserProfilePageComponent,
      },
      {
        path: 'user/password',
        pathMatch: 'full',
        component: UpdateUserPasswordPageComponent,
      },
      {
        path: 'user/email',
        pathMatch: 'full',
        component: UpdateUserEmailPageComponent,
      },
      {
        path: 'orders',
        pathMatch: 'full',
        component: OrderListPageComponent,
      },
      {
        path: 'affiliate/dashboard',
        pathMatch: 'full',
        component: AffiliatePageComponent,
      },
      {
        path: 'affiliate/form',
        pathMatch: 'full',
        component: AffiliateFormComponent,
      },
      {
        path: 'affiliate',
        pathMatch: 'full',
        component: AffiliateAvertPageComponent,
      },
      {
        path: 'withdraw',
        pathMatch: 'full',
        component: WithdrawPageComponent,
      },
      {
        path: 'refundpolicy',
        pathMatch: 'full',
        component: RefundPolicyPageComponent,
      },
      {
        path: 'privacypolicy',
        pathMatch: 'full',
        component: PrivacyPolicyPageComponent,
      },
      {
        path: 'aboutus',
        pathMatch: 'full',
        component: AboutUsPageComponent,
      },
      {
        path: 'contact',
        pathMatch: 'full',
        component: ContactUsPageComponent,
      },
      {
        path: 'contact/successful',
        pathMatch: 'full',
        component: ContactUsSendSuccessfulPageComponent,
      },
      {
        path: 'terms-condition',
        pathMatch: 'full',
        component: TermsConditionPageComponent,
      },
      {
        path: 'iptv-tutorial',
        pathMatch: 'full',
        component: IptvTutorialPageComponent,
      },
      // {
      //   path: 'company',
      //   pathMatch: 'full',
      //   component: CompanyPageComponent,
      // },
      {
        path: ':s', // 捕获路径中的验证码
        component: IndexPageComponent, 
      },
      {
        path: '**',
        pathMatch: 'full',
        component: NotFoundPageComponent,
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      scrollOffset: [0, 0],
    }),
    RecaptchaV3Module,
  ],
  exports: [RouterModule],
  providers: [
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.googleReCaptchaSiteKey,
    },
    {
      provide: RECAPTCHA_BASE_URL,
      useValue: 'https://recaptcha.net/recaptcha/api.js', // use recaptcha.net script source for some of our users
    },
  ],
})
export class AppRoutingModule {}
