<div class="close">
  <button mat-dialog-close>
    <img
      alt="del"
      width="24"
      height="24"
      src="../../../../assets/images/base/Icon-del-default.svg"
    />
  </button>
</div>
<h1>{{ "modalSelectPayment.SelectPayment" | translate }}</h1>
<div class="top-margin"></div>
<div class="top-divider"></div>

<!-- 等支付接口对接好再上线 -->
<mat-dialog-content class="mat-content" *ngIf="slectPayment" >
  <app-spinner [loading]="loading">
    <div class="tip">{{ "modalSelectPayment.Tip" | translate }}</div>
    <div class="payments">
<!--      <div class="payment" style="width: 100%">-->
<!--        <div class="title">{{ "modalSelectPayment.Method" | translate }} 1</div>-->
<!--        <mat-radio-group-->
<!--          [(ngModel)]="methodSelected.method"-->
<!--          (ngModelChange)="setMethodSelected('airwallex')"-->
<!--          aria-labelledby="example-radio-group-label"-->
<!--          class="content"-->
<!--        >-->
<!--          <mat-radio-button class="example-margin" value="airwallex">-->
<!--          </mat-radio-button>-->
<!--          <div class="method">-->
<!--            <ng-container *ngFor="let data of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]">-->
<!--              <img-->
<!--                src="../../../../assets/images/airwallex/{{ data + 1 }}.png"-->
<!--                style="margin-right: 10px"-->
<!--              />-->
<!--            </ng-container>-->
<!--          </div>-->

<!--        </mat-radio-group>-->
<!--        <div class="rate">+0.0% <span>+5.0%</span></div>-->
<!--      </div>-->

        <div  class="payment"  style="width: 100%" *ngIf="!kr">
            <!--                <div class="title">{{ "modalSelectPayment.Method" | translate }} 1</div>-->
            <mat-radio-group
                    [(ngModel)]="methodSelected.method"
                    (ngModelChange)="setMethodSelected('useepay')"
                    aria-labelledby="example-radio-group-label"
                    class="content"
            >
                <mat-radio-button class="example-margin" value="useepay">
                </mat-radio-button>
                <div class="method">
<!--                    <ng-container *ngFor="let data of [1, 2, 3, 4]">-->
<!--                      <img-->
<!--                        src="../../../../assets/images/useepay/{{ data }}.png"-->
<!--                        style="margin-right: 10px"-->
<!--                      />-->
<!--                    </ng-container>-->
                    <img style="height: 65px; " src="../../../../assets/images/useepay/twUseepay.png" />
                </div>
<!--                <div class="method" *ngIf="kr">-->
<!--                    <ng-container *ngFor="let data of [111,112,113,114,115]">-->
<!--                        <img-->
<!--                                src="../../../../assets/images/useepay/{{ data }}.png"-->
<!--                                style="margin-right: 10px"-->
<!--                        />-->
<!--                    </ng-container>-->
<!--                </div>-->

            </mat-radio-group>
        </div>

              <div  *ngIf="jiekopay" class="payment"  style="width: 100%">
<!--                <div class="title">{{ "modalSelectPayment.Method" | translate }} 1</div>-->
                <mat-radio-group
                  [(ngModel)]="methodSelected.method"
                  (ngModelChange)="setMethodSelected('jiekopay')"
                  aria-labelledby="example-radio-group-label"
                  class="content"
                >
                  <mat-radio-button class="example-margin" value="jiekopay">
                  </mat-radio-button>
                    <div class="method">
                        <img style="height: 65px; " src="../../../../assets/images/jiekopay/twJiekopay.png" />
                    </div>

                </mat-radio-group>
<!--                <div class="rate">+0.0% <span>+6.0%</span></div>-->
              </div>

        <div class="payment" *ngIf="pagsmile" style="width: 100%">
            <!--                <div class="title">{{ "modalSelectPayment.Method" | translate }} 1</div>-->
            <mat-radio-group
                    [(ngModel)]="methodSelected.method"
                    (ngModelChange)="setMethodSelected('pagsmile')"
                    aria-labelledby="example-radio-group-label"
                    class="content"
            >
                <mat-radio-button class="example-margin" value="pagsmile">
                </mat-radio-button>
                <div class="method">
<!--                    <ng-container *ngFor="let data of ['Creditcard_kr','KakaoPay','Payco', 'Samsung Pay', 'Toss']">&ndash;&gt;-->
<!--                        <img-->
<!--                                src="../../../../assets/images/pagsmile/{{ data }}.png"-->
<!--                                style="margin-right: 10px"-->
<!--                        />-->
<!--                    </ng-container>-->
                    <img style="height: 65px; " src="../../../../assets/images/pagsmile/krPagsmile.png" />
                </div>

            </mat-radio-group>
<!--            <div class="rate">+0.0% <span>+3.0%</span></div>-->
        </div>

        <div class="payment" *ngIf="v5pay" style="width: 100%">
            <!--                <div class="title">{{ "modalSelectPayment.Method" | translate }} 1</div>-->
            <mat-radio-group
                    [(ngModel)]="methodSelected.method"
                    (ngModelChange)="setMethodSelected('v5pay')"
                    aria-labelledby="example-radio-group-label"
                    class="content"
            >
                <mat-radio-button class="example-margin" value="v5pay">
                </mat-radio-button>
                <div class="method">
<!--                    <ng-container *ngFor="let data of ['Creditcard_kr', 'KakaoPay', 'NaverPay', 'Payco']">&ndash;&gt;-->
<!--                        <img-->
<!--                                src="../../../../assets/images/v5pay/{{ data }}.png"-->
<!--                                style="margin-right: 10px"-->
<!--                        />-->
<!--                    </ng-container>-->

                    <img style="height: 65px; " src="../../../../assets/images/v5pay/krV5Pay.png" />
                </div>

            </mat-radio-group>
<!--            <div class="rate">+0.0% <span>+6.0%</span></div>-->
        </div>

        <div  class="payment"  style="width: 100%">
            <!--                <div class="title">{{ "modalSelectPayment.Method" | translate }} 1</div>-->
            <mat-radio-group
                    [(ngModel)]="methodSelected.method"
                    (ngModelChange)="setMethodSelected('antom')"
                    aria-labelledby="example-radio-group-label"
                    class="content"
            >
                <mat-radio-button class="example-margin" value="antom">
                </mat-radio-button>
                <div class="method" *ngIf="!kr">
<!--                    <ng-container *ngFor="let data of [1]">-->
<!--                        <img-->
<!--                                src="../../../../assets/images/antom/{{ data }}.png"-->
<!--                                style="margin-right: 10px"-->
<!--                        />-->
<!--                    </ng-container>-->
                    <img style="height: 65px; "
                            src="../../../../assets/images/antom/twAntom.png"
                    />
                </div>
                <div class="method" *ngIf="kr">
<!--                    <ng-container *ngFor="let data of [111,112,113,114]">-->
<!--                        <img-->
<!--                                src="../../../../assets/images/antom/{{ data }}.png"-->
<!--                                style="margin-right: 10px"-->
<!--                        />-->
<!--                    </ng-container>-->
                    <img style="height: 65px; "
                            src="../../../../assets/images/antom/krAntom.png"
                    />
                </div>

            </mat-radio-group>
        </div>

<!--      <div *ngFor="let p of payments; index as i" class="payment">-->
<!--&lt;!&ndash;        <div class="title">&ndash;&gt;-->
<!--&lt;!&ndash;          {{ "modalSelectPayment.Method" | translate }} {{ i + 1 }}&ndash;&gt;-->
<!--&lt;!&ndash;        </div>&ndash;&gt;-->
<!--        <mat-radio-group-->
<!--          [(ngModel)]="methodSelected.submethod"-->
<!--          (ngModelChange)="setMethodSelected('Payssion')"-->
<!--          aria-labelledby="example-radio-group-label"-->
<!--          class="content"-->
<!--        >-->
<!--          <mat-radio-button-->
<!--            class="example-margin"-->
<!--            [value]="p.code"-->
<!--          ></mat-radio-button>-->
<!--          <div class="method">-->
<!--            <img src="../../../../assets/images/payssion/{{ p.code }}.png" />-->
<!--          </div>-->
<!--        </mat-radio-group>-->
<!--        <div class="rate">+0.0% <span>+5.0%</span></div>-->
<!--      </div>-->
    </div>

  </app-spinner>
</mat-dialog-content>
<mat-dialog-content class="mat-content">
    <div class="button">
        <app-button (click)="pay(methodSelected.method, methodSelected.submethod)"
                    background="#612069"
                    width="100%"
                    height="50px"
                    fontColor="#ffffff">
            {{ "button.CheckOut" | translate }}
        </app-button>
    </div>
</mat-dialog-content>
<mat-dialog-content  *ngIf="payPal" class="mat-content">
  <ngx-paypal [config]="payPalConfig"></ngx-paypal>
</mat-dialog-content>