import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-after-withdraw-modal',
  templateUrl: './after-withdraw-modal.component.html',
  styleUrls: ['./after-withdraw-modal.component.scss']
})
export class AfterWithdrawModalComponent {
  isSmall: any;
  constructor(
    private title: Title,
    private meta: Meta,
    private breakpointObserver: BreakpointObserver
  ) {
    this.isSmall = this.breakpointObserver.isMatched([
      Breakpoints.XSmall,
      Breakpoints.Small,
    ]);
    if (this.isSmall) {
      this.title.setTitle('PremLogin');
    } else {
      this.title.setTitle('Contact US - PremLogin');
    }

    this.meta.updateTag({
      name: 'description',
      content: 'Contact PremLogin team',
    });
  }

  openExternalLink(url: string) {
    window.open(url, '_blank'); // 新标签页打开
    // 或
    // location.href = url;       // 当前页跳转
  }


}

