import { Component, Input, OnChanges } from '@angular/core';
import { RenewCheckModalComponent } from '../renew-check-modal/renew-check-modal.component';
import { DialogService } from 'src/app/service/dialog.service';
import { Crisp } from 'crisp-sdk-web';
import { JoinGroupModalComponent } from '../join-group-modal/join-group-modal.component';
import { CarpoolUsersModalComponent } from '../carpool-users-modal/carpool-users-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { OrderApiService } from 'src/app/service/order-api.service';
import { NetflixCodeModalComponent } from '../netflix-code-modal/netflix-code-modal.component';
import { TipNetflixCodeModalComponent } from '../tip-netflix-code-modal/tip-netflix-code-modal.component';
import { RefreshNetflixCodeModalComponent } from '../refresh-netflix-code-modal/refresh-netflix-code-modal.component';
import { CreateNewTicketModalComponent } from '../../help/create-new-ticket-modal/create-new-ticket-modal.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bus-seat-card',
  templateUrl: './bus-seat-card.component.html',
  styleUrls: ['./bus-seat-card.component.scss'],
})
export class BusSeatCardComponent implements OnChanges {
  // 控制support菜单的开启
  isSmall = false;
  menuOpen = false;
  imgSrc = '../../../../assets/images/subscription/Icon-live-chart.svg';
  timeout = 91;
  @Input() data: any;
  time = '';
  interval: any;
  loading = false;
  userRegion = '';

  onMenuOpened() {
    this.menuOpen = true;
    this.imgSrc = '../../../../assets/images/subscription/Icon-live-chart-selected.svg';


  }

  onMenuClosed() {
    this.menuOpen = false;
    this.imgSrc = '../../../../assets/images/subscription/Icon-live-chart.svg';
  }


  constructor(
    private breakpointObserver: BreakpointObserver,
    private myDialog: DialogService, 
    private dialog: MatDialog, 
    private api: OrderApiService,
    private router: Router
  ) {
    this.breakpointObserver
      .observe([Breakpoints.XSmall])
      .subscribe((r) => {
        this.isSmall = r.matches;
      });
    
    this.interval = setInterval(() => {
      this.countDown();
    }, 1000);
    var region = localStorage.getItem('region');
    if (region) {
      this.userRegion = region;
      console.log(this.userRegion);
    }
  }

  renewButton = true;
  ngOnChanges(): void {
    this.countDown();
    if (this.data.bus.bus_account)
      this.renewButton =
        this.data.bus.bus_type.renewable &&
        this.data.bus.bus_account.status === 'Online' &&
        this.data.bus.bus_type.status === 'Online' &&
        this.data.bus.platform.status === 'Online';
    else
      this.renewButton =
        this.data.bus.bus_type.renewable &&
        this.data.bus.bus_type.status === 'Online' &&
        this.data.bus.platform.status === 'Online';
  }

  newTicket() {
    this.dialog
      .open(CreateNewTicketModalComponent, {
        width: '726px',
        data: this.data,
        panelClass: 'order-dialog-container', // 添加自定义样式类
      })
      .afterClosed()
      .subscribe((r) => {
        if (r) {
          this.viewTicket();
        }
      });
  }

  viewTicket() {
    this.router.navigate(['/help']);
  }


  renew() {
    console.log('renew', this.renewButton)
    if (!this.renewButton) return;
    this.myDialog.open(RenewCheckModalComponent, {
      data: this.data,
      width: '550px',
      panelClass: 'order-dialog-container', // 添加自定义样式类
    });
  }

  openHelp() {
    window.open(this.data.bus.platform.tips);
  }

  openNetflixCodeModal() {
    this.timeout = 91;
    this.dialog
      .open(NetflixCodeModalComponent, {
        panelClass: 'subscription-tips-dialog'
      })
      .afterClosed()
      .subscribe((r) => {
        if (r) this.getLink();
      });
  }

  openTipModal() {
    this.timeout = 91;
    this.dialog
      .open(TipNetflixCodeModalComponent, {
        panelClass: 'order-dialog-container'
      })
      .afterClosed()
      .subscribe((r) => {
        if (r) {
          this.getLink();
        }
      });
  }
  link: any;

  async getLink() {
    try {
      this.timeout -= 1;
      this.loading = true;
      await this.api.getNetflixLink(this.data.id);
      if (this.timeout > 0) {
        let interval = setInterval(async () => {
          this.timeout -= 1;
          const r = await this.api.getNetflixSelectLink(this.data.id);
          if (r.data) {
            this.link = r.data;
            clearInterval(interval);
          }
          if (this.timeout === 0) {
            clearInterval(interval);
          }
        }, 1000);
      }
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }

  code: any;
  getCode() {
    this.timeout = 91;
    this.getChatCode();
  }

  async getChatCode() {
    try {
      this.timeout -= 1;
      this.loading = true;
      await this.api.getChatGPTCode(this.data.id);
      if (this.timeout > 0) {
        let interval = setInterval(async () => {
          this.timeout -= 1;
          const r = await this.api.getChatGPTSelectCode(this.data.id);
          if (r.data) {
            this.code = r.data;
            clearInterval(interval);
          }
          if (this.timeout === 0) {
            clearInterval(interval);
          }
        }, 1000);
      }
    } catch (error) {
    } finally {
      this.loading = false;
    }
  }




  refresh() {
    this.timeout = 91;
    this.dialog
      .open(RefreshNetflixCodeModalComponent, {
        panelClass: 'order-dialog-container'
      })
      .afterClosed()
      .subscribe((r) => {
        if (r) {
          this.getLink();
        }
      });
  }

  openLink() {
    window.open(this.link);
  }

  openLink1() {
    window.open("https://lin.ee/EhWpPfQ");
  }

  copyText(text: string) {
    try {
      // 现代浏览器 Clipboard API
      if (navigator.clipboard) {
        navigator.clipboard.writeText(text)
            .then(() => console.log('复制成功！'))
      } else {
        // 旧浏览器回退方案
        const textarea = document.createElement('textarea');
        textarea.value = text;
        textarea.style.position = 'fixed';
        textarea.style.opacity = '0';
        document.body.appendChild(textarea);
        textarea.select();

        // iOS设备特殊处理
        if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
          const range = document.createRange();
          range.selectNodeContents(textarea);
          const selection = window.getSelection();
          if (selection) {
            selection.removeAllRanges();
            selection.addRange(range);
          }
          textarea.setSelectionRange(0, 999999);
        }

        const success = document.execCommand('copy');
        document.body.removeChild(textarea);
        console.log('复制成功！')
      }


    } catch (err) {
      console.error('复制失败:', err);
    }
  }

  groups() {
    this.myDialog.open(JoinGroupModalComponent, {
      width: '980px',
      panelClass: 'order-dialog-container', // 添加自定义样式类
    });
  }

  chat() {
    Crisp.chat.open();
  }

  checkCarpoolUsers() {
    this.dialog.open(CarpoolUsersModalComponent, {
      data: this.data,
      panelClass: 'order-dialog-container', // 添加自定义样式类
      width: '894px',
    });
  }

  countDown() {
    const diffInMilliseconds =
      new Date().getTime() - new Date(this.data.created_time).getTime();
    const diffInTime = 86400000 - diffInMilliseconds;

    if (diffInTime < 0) {
      this.time = '00h 00m 00s';
      clearInterval(this.interval);
    } else {
      const diffInHours = diffInTime / 3600000;
      const diffInMinutes = diffInHours * 60;
      const diffInSeconds = diffInMinutes * 60;

      const hours = Math.floor(diffInHours);
      const minutes = Math.floor(diffInMinutes) - Math.floor(diffInHours) * 60;
      const seconds =
        Math.floor(diffInSeconds) - Math.floor(diffInMinutes) * 60;

      this.time =
        (hours >= 10 ? hours : '0' + hours) +
        'h ' +
        (minutes >= 10 ? minutes : '0' + minutes) +
        'm ' +
        (seconds >= 10 ? seconds : '0' + seconds) +
        's';
    }
  }
}
