<div class="container">
  <div class="content">
    <app-spinner [loading]="loadingAffiliate">
      <div class="withdrawal">
        <mat-card>
          <mat-card-title>
            <span>{{ "pageWithdraw.Withdraw" | translate }}</span>
          </mat-card-title>
          <mat-card-content>
            <form [formGroup]="formGroup">
              <div class="item">
                <span>{{ "pageWithdraw.WithdrawableAmount" | translate }}</span>
                <span style="color: #d6392e">{{
                  formGroup.value.currency === "usd"
                    ? "$ " + (commission?.usd | number : ".2")
                    : "€ " + (commission?.eur | number : ".2")
                }}</span>
              </div>
              <div class="item">
                <app-no-border-input
                  [label]="'pageWithdraw.AmountLabel' | translate"
                  formControlName="amount"
                ></app-no-border-input>
              </div>
              <div class="item">
                <span>{{ "pageWithdraw.Currency" | translate }}</span>
                <app-currency-select
                  formControlName="currency"
                ></app-currency-select>
              </div>
              <div class="item">
                <span>{{ "pageWithdraw.WithdrawalMethods" | translate }}</span>
                <app-payment-method-select
                  formControlName="method"
                ></app-payment-method-select>
              </div>

                <div class="item" *ngIf="formGroup.value['method'] === '台湾银行'">
                    <span>{{ "pageWithdraw.ChooseBank" | translate }}</span>
                    <app-tw-bank-select
                            formControlName="bankcode"
                    ></app-tw-bank-select>
                </div>

              <div
                class="item"
                *ngIf="(formGroup.value['method'] === 'Paypal' || formGroup.value['method'] === 'Bank Transfer' || formGroup.value['method'] === 'BTC')"
              >
                <app-no-border-input
                  [label]="'pageWithdraw.AccountLabel' | translate"
                  formControlName="account"
                ></app-no-border-input>
              </div>

            <div class="item" *ngIf="formGroup.value['method'] === 'Korean Bank'">
                <span>{{ "pageWithdraw.ChooseBank" | translate }}</span>
                <app-korean-bank-select
                        formControlName="bankcode"
                ></app-korean-bank-select>
            </div>

              <div
                class="item"
                *ngIf="(formGroup.value['method'] === 'Korean Bank' || formGroup.value['method'] === '街口支付' || formGroup.value['method'] === '台湾银行')"
              >
                <app-no-border-input
                  [label]="'pageWithdraw.AccountKakao' | translate"
                  formControlName="bank"
                ></app-no-border-input>
              </div>
              <div
                class="item"
                *ngIf="(formGroup.value['method'] === 'Korean Bank')"
              >
                <app-no-border-input
                  [label]="'pageWithdraw.AccountNameKakao' | translate"
                  formControlName="name"
                ></app-no-border-input>
              </div>
            </form>
            <div class="tip">
              <span class="start">*</span>
              <span>{{ "pageWithdraw.Tip" | translate }}</span>
            </div>
            <div class="error">
              <app-error
                *ngIf="
                  error.account &&
                  formGroup.controls['account'].hasError('required')
                "
                >{{ "textError.AccountRequired" | translate }}</app-error
              >
              <app-error
                *ngIf="
                  error.account &&
                  formGroup.controls['account'].hasError('email')
                "
                >{{ "textError.EnterEmail" | translate }}</app-error
              >
              <app-error
                *ngIf="
                  error.amount &&
                  formGroup.controls['amount'].hasError('required')
                "
                >{{ "textError.AmountRequired" | translate }}</app-error
              >
              <app-error
                *ngIf="
                  error.amount &&
                  (formGroup.controls['amount'].hasError('min') ||
                    formGroup.controls['amount'].hasError('pattern'))
                "
                >{{ "textError.EnterNumber" | translate }}</app-error
              >
              <app-error *ngIf="error.bank">
                {{ "textError.BankCardNumber" | translate }}
              </app-error>
              <app-error *ngIf="error.name">
                {{ "textError.DepositorName" | translate }}
              </app-error>
                <app-error *ngIf="error.bankcode">
                    {{ "textError.BankRequired" | translate }}
                </app-error>
            </div>
            <div class="action">
              <div class="button">
                <app-button
                  color="#602369"
                  fontColor="#ffffff"
                  height="48px"
                  width="100%"
                  [loading]="loadingAffiliate"
                  (click)="withdraw()"
                  >{{ "pageWithdraw.PaypalWithdraw" | translate }}</app-button
                >
              </div>
            </div>
          </mat-card-content>
        </mat-card>
      </div>
    </app-spinner>
    <div class="divider" *ngIf="!isSmall"></div>
    <app-withdrawal-list
      [records]="records"
      [loading]="loading"
      *ngIf="!isSmall"
    ></app-withdrawal-list>
    <app-withdrawal-list-cards
      [records]="records"
      [loading]="loading"
      *ngIf="isSmall"
    >
    </app-withdrawal-list-cards>
    <div class="pagination" *ngIf="records.length !== 0">
      <app-paginator
        [currentPage]="page"
        [total]="total"
        [pageSize]="pageSize"
        (pageChange)="onPageChange($event)"
      ></app-paginator>
    </div>
  </div>
</div>
