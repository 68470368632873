import { Component } from '@angular/core';

@Component({
  selector: 'app-iptv-tutorial-page',
  templateUrl: './iptv-tutorial-page.component.html',
  styleUrls: ['./iptv-tutorial-page.component.scss']
})
export class IptvTutorialPageComponent {

}
