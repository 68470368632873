import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root',
})
export class UserApiService {
  constructor(private http: HttpService) {}

  buildurl(path: string, queryMap: any = null): string {
    return this.http.buildurl('/user' + path, queryMap);
  }

  sendLoginCode(email: string, token: string) {
    const url = this.buildurl('/login/code');
    const body = { email, token };
    return this.http.post(url, body);
  }

  // 更新邮件api
  sendUpdateCode(email: string, token: string) {
    const url = this.buildurl('/email/code');
    const body = { email, token };
    return this.http.post(url, body);
  }

  loginWithEmail(code: string, email: string, token: string) {
    const url = this.buildurl('/login');
    const body = { code, email, token };
    return this.http.put(url, body);
  }

  loginWithPassword(email: string, password: string, token: string) {
    const url = this.buildurl('/plogin');
    const body = { email, password, token };
    return this.http.put(url, body);
  }

  loginWithGoogle(
    email: string,
    nickname: string,
    avatar: string,
    idtoken: string
  ) {
    const url = this.buildurl('/googleauth');
    const body = {
      email,
      nickname,
      avatar,
      idtoken,
    };
    return this.http.put(url, body);
  }

  checkAffiliateCode(code: string) {
    const url = this.buildurl(`/affiliate/${code}`);
    return this.http.fget(url);
  }

  getCheckEmail(email: string, token: string) {
    const url = this.buildurl(`/user/${email}`, { token });
    return this.http.fget(url);
  }

  updateEmailPassword(password: string) {
    const url = this.buildurl('/password');
    return this.http.put(url, { password });
  }

  getSelf() {
    const url = this.buildurl('/self', { try: true });
    return this.http.fget(url);
  }

  logout() {
    const url = this.buildurl('/logout');
    return this.http.put(url, {});
  }

  updateNickname(nickname: string) {
    const url = this.buildurl('/nickname');
    const body = { nickname };
    return this.http.put(url, body);
  }

  updateEmail(code: string, email: string, token: string) {
    const url = this.buildurl('/email');
    const body = { code, email, token };
    return this.http.put(url, body);
  }

  findUserAvatars() {
    const url = this.buildurl('/avatars');
    return this.http.fget(url);
  }

  updateUserAvatar(avatar: string) {
    const url = this.buildurl('/avatar');
    const body = { avatar };
    return this.http.put(url, body);
  }

  findUserBusSeats(query: string) {
    const url = this.buildurl('/bus/seats', { query });
    return this.http.fget(url);
  }

  findAffiliate() {
    const url = this.buildurl('/affiliate');
    return this.http.fget(url);
  }

  findAffiliateOrders(page: number, page_size: number) {
    const url = this.buildurl('/affiliate/orders', { page, page_size });
    return this.http.fget(url);
  }

  findUserWithdrawals(page: number, page_size: number) {
    const url = this.buildurl('/affiliate/withdrawals', { page, page_size });
    return this.http.fget(url);
  }

  postUserWithdrawal(
    account: string,
    account_name: string,
    amount: number,
    currency: string,
    payment_method: string,
    bankcode:string,
    payout: boolean,
    remark: string
  ) {
    const url = this.buildurl('/affiliate/withdrawal');
    const body = {
      account,
      account_name,
      amount,
      currency,
      payment_method,
      bankcode,
      payout,
      remark,
    };
    return this.http.post(url, body);
  }

  getUserAffiliateG2Orders() {
    const url = this.buildurl('/affiliate/g2/orders');
    return this.http.fget(url);
  }

  getUserAffiliateG2Platforms() {
    const url = this.buildurl('/affiliate/g2/platforms');
    return this.http.fget(url);
  }

  sendMessage(
    company: string,
    email: string,
    message: string,
    name: string,
    fileid: string
  ) {
    const url = this.buildurl('/form');
    const body = { company, email, message, name, fileid };
    return this.http.post(url, body);
  }

  getCarpoolUsers(id: number) {
    const url = this.buildurl(`/bus/seat/${id}/users`);
    return this.http.fget(url);
  }

  postInvitationLink(id: number, email: string) {
    const url = this.buildurl(`/bus/seat/${id}/link`);
    const body = { email };
    return this.http.post(url, body);
  }

  getAffiliateCommissionList(
    typeslist: number,
    page: number,
    page_size: number
  ) {
    const q = { typeslist, page, page_size };
    const url = this.buildurl('/affiliate/commissionlist', q);
    return this.http.fget(url);
  }
}
