<mat-card>
    <mat-card-content>
        <div class="item">
            <div class="title">
                {{ "pageWithdraw.WithdrawalTime" | translate }}
            </div>
            <div class="content">
                <app-datetime [text]="data.created_time"></app-datetime>
            </div>
        </div>
        <div class="item">
            <div class="title">
                {{ "pageWithdraw.WithdrawalMethod" | translate }}
            </div>
            <div class="content">
                {{ data.payment_method }}
            </div>
        </div>
        <div class="item">
            <div class="title">
                {{ "pageWithdraw.WithdrawalStatus" | translate }}
            </div>
            <div class="content">
                <app-withdrawal-status [status]="data.status"></app-withdrawal-status>
            </div>
        </div>
        <div class="item">
            <div class="title">
                {{ "pageWithdraw.Account" | translate }}
            </div>
            <div class="content">
                {{ data.account }}
            </div>
        </div>
        <div class="item">
            <div class="title">
                {{ "pageWithdraw.Amount" | translate }}
            </div>
            <div class="content">
                <app-money
                    [price]="{ currency: data.currency, value: data.amount }"
                    fontSize="16px"
                ></app-money>
            </div>
        </div>

    </mat-card-content>    
</mat-card>