import { Component } from '@angular/core';

@Component({
  selector: 'app-iptv-tutorial-title',
  templateUrl: './iptv-tutorial-title.component.html',
  styleUrls: ['./iptv-tutorial-title.component.scss']
})
export class IptvTutorialTitleComponent {

}
