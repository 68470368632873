<mat-tab-group mat-align-tabs="center" [(selectedIndex)]="selectedIndex" id="showPro">
    <mat-tab>
        <ng-template mat-tab-label id="SAMSUNG">
            {{ "pageIPTVTutorial.SAMSUNG" | translate }}
        </ng-template>
        <ng-template matTabContent>
            <app-spinner [loading]="loading">
                <div>
                    <article class='mat-typography'>
                        <div class='card'>
                            <p class="show-title"> {{ "pageIPTVTutorialSAMSUNG.title" | translate }}</p>
                            <p class="show-subtitle"> {{ "pageIPTVTutorialSAMSUNG.subtitle" | translate }}</p>
                            <div class='content'>
                                <p> <span class="title-l">1</span> {{ "pageIPTVTutorialSAMSUNG.title1" | translate }}</p>

                                <p class="img-container">
                                <img src="../../../../assets/images/iptv-tutorial/SAMSUNG1.png" />
                                </p>
                                <p> <span class="title-l">2</span> {{ "pageIPTVTutorialSAMSUNG.title2" | translate }}</p>
                                <p class="img-container">
                                    <img src="../../../../assets/images/iptv-tutorial/SAMSUNG2.png" />
                                </p>
                                <p> <span class="title-l">3</span> {{ "pageIPTVTutorialSAMSUNG.title3" | translate }}</p>
                                <p class="img-container">
                                    <img src="../../../../assets/images/iptv-tutorial/SAMSUNG3.png" />
                                </p>
                                <p> <span class="title-l">4</span> {{ "pageIPTVTutorialSAMSUNG.title4" | translate }}</p>
                                <p class="show-line"> <span class="title-l3"></span><a class="show-bt" target="_blank" rel="noopener noreferrer" href="https://smartone-iptv.com/plugin/smart_one/main_generate">https://smartone-iptv.com/plugin/smart_one/main_generate</a>{{ "pageIPTVTutorialSAMSUNG.title4-1" | translate }}</p>
                                <ul>
                                    <li>
                                        <p class="show-ct">{{ "pageIPTVTutorialSAMSUNG.title4-2" | translate }}</p>
                                    </li>
                                    <li>
                                        <p class="show-ct">{{ "pageIPTVTutorialSAMSUNG.title4-3" | translate }}</p>
                                    </li>
                                    <li>
                                        <p class="show-ct">{{ "pageIPTVTutorialSAMSUNG.title4-4" | translate }}</p>
                                    </li>
                                </ul>
                                <p class="img-container">
                                    <img src="../../../../assets/images/iptv-tutorial/SAMSUNG4.png" />
                                </p>
                                <p> <span class="title-l">5</span><span>{{ "pageIPTVTutorialSAMSUNG.title5" | translate }}</span> </p>
                                <p> <span class="title-l">6</span> {{ "pageIPTVTutorialSAMSUNG.title6" | translate }}</p>

                            </div>

                        </div>
                        <div class="card-sd" ><span>{{ "pageIPTVTutorial.BuyTrialPlan" | translate }}</span></div>
                        <div class='card' style="padding: 0px;margin-top: 0px;">
                            <div class="premium-banner">
                                <div class="banner-content">
                                    <div class="left-section">
                                        <div class="crown-icon">
                                            <img src="../../../../assets/images/iptv-tutorial/Frame.png" alt="crown">
                                        </div>
                                        <div class="text-content">
                                            <p class="premium-t"> {{ "pageIPTVTutorial.BuyTrialPlanTitle1" | translate }}</p>
                                            <p class="premium-2"> {{ "pageIPTVTutorial.BuyTrialPlanTitle2" | translate }}</p>
                                        </div>
                                    </div>
                                    <button class="buy-button">
                                        {{ "pageIPTVTutorial.BuyNow" | translate }} →
<!--                                        <span class="arrow">→</span>-->
                                    </button>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </app-spinner>
        </ng-template>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label id="LG">
            {{ "pageIPTVTutorial.LG" | translate }}

        </ng-template>
        <ng-template matTabContent>
            <app-spinner [loading]="loading">
                <div>
                    <article class='mat-typography'>
                        <div class='card'>
                            <p class="show-title"> {{ "pageIPTVTutorialLG.title" | translate }}</p>
                            <p class="show-subtitle"> {{ "pageIPTVTutorialLG.subtitle" | translate }}</p>
                            <div class='content'>
                                <p> <span class="title-l">1</span> {{ "pageIPTVTutorialLG.title1" | translate }}</p>
                                <ul>
                                    <li>
                                        <p class="show-ct">{{ "pageIPTVTutorialLG.title1-1" | translate }}</p>
                                    </li>
                                    <li>
                                        <p class="show-ct">{{ "pageIPTVTutorialLG.title1-2" | translate }}</p>
                                    </li>
                                </ul>
                                <p> <span class="title-l">2</span> {{ "pageIPTVTutorialLG.title2" | translate }}</p>
                                <ul>
                                    <li>
                                        <p class="show-ct">{{ "pageIPTVTutorialLG.title2-1" | translate }}</p>
                                    </li>
                                </ul>
                                <p class="img-container">
                                    <img src="../../../../assets/images/iptv-tutorial/LG1.png" />
                                </p>
                                <ul>
                                    <li>
                                        <p class="show-ct">{{ "pageIPTVTutorialLG.title2-2" | translate }}</p>
                                    </li>
                                    <li>
                                        <p class="show-ct">{{ "pageIPTVTutorialLG.title2-3" | translate }}</p>
                                    </li>
                                </ul>
                                <p class="img-container">
                                    <img src="../../../../assets/images/iptv-tutorial/LG2.png" />
                                </p>
                                <ul>
                                    <li>
                                        <p class="show-ct">{{ "pageIPTVTutorialLG.title2-4" | translate }}</p>
                                    </li>
                                </ul>
                                <p> <span class="title-l">3</span> {{ "pageIPTVTutorialLG.title3" | translate }}</p>
                                <ul>
                                    <li>
                                        <p class="show-ct">{{ "pageIPTVTutorialLG.title3-1" | translate }} <a class="show-bt" target="_blank" rel="noopener noreferrer" href="https://ss-iptv.com/en/users/playlist">https://ss-iptv.com/en/users/playlist</a> {{ "pageIPTVTutorialLG.title3-2" | translate }}</p>
                                    </li>
                                    <li>
                                        <p class="show-ct">{{ "pageIPTVTutorialLG.title3-3" | translate }}</p>
                                    </li>
                                    <li>
                                        <p class="show-ct">{{ "pageIPTVTutorialLG.title3-4" | translate }}</p>
                                    </li>

                                </ul>
                                <p class="img-container">
                                    <img src="../../../../assets/images/iptv-tutorial/LG3.png" />
                                </p>
                                <p> <span class="title-l">4</span> {{ "pageIPTVTutorialLG.title4" | translate }}</p>
                                <ul>
                                    <li>
                                        <p class="show-ct">{{ "pageIPTVTutorialLG.title4-1" | translate }}</p>
                                    </li>
                                </ul>
                                <p class="img-container">
                                    <img src="../../../../assets/images/iptv-tutorial/LG4.png" />
                                </p>
                                <ul>
                                    <li>
                                        <p class="show-ct">{{ "pageIPTVTutorialLG.title4-2" | translate }}</p>
                                    </li>
                                </ul>
                                <p class="img-container">
                                    <img src="../../../../assets/images/iptv-tutorial/LG5.png" />
                                </p>
                                <ul>
                                    <li>
                                        <p class="show-ct">{{ "pageIPTVTutorialLG.title4-3" | translate }}</p>
                                    </li>
                                    <li>
                                        <p class="show-ct">{{ "pageIPTVTutorialLG.title4-4" | translate }}</p>
                                    </li>
                                    <li>
                                        <p class="show-ct">{{ "pageIPTVTutorialLG.title4-5" | translate }}</p>
                                    </li>
                                </ul>
                                <p class="img-container">
                                    <img src="../../../../assets/images/iptv-tutorial/LG6.png" />
                                </p>
                                <p> <span class="title-l">5</span><span>{{ "pageIPTVTutorialLG.title5" | translate }}</span> </p>
                                <ul>
                                    <li>
                                        <p class="show-ct">{{ "pageIPTVTutorialLG.title5-1" | translate }}</p>
                                    </li>
                                </ul>
                                <p class="img-container">
                                    <img src="../../../../assets/images/iptv-tutorial/LG7.png" />
                                </p>
                                <ul>
                                    <li>
                                        <p class="show-ct">{{ "pageIPTVTutorialLG.title5-2" | translate }}</p>
                                    </li>
                                    <li>
                                        <p class="show-ct">{{ "pageIPTVTutorialLG.title5-3" | translate }}</p>
                                    </li>
                                </ul>
                                <p style="align-items: center;
        justify-content: center;text-align: center;"><span > {{ "pageIPTVTutorialLG.title6" | translate }}</span> </p>
                            </div>

                        </div>
                        <div class="card-sd" ><span>{{ "pageIPTVTutorial.BuyTrialPlan" | translate }}</span></div>
                        <div class='card' style="padding: 0px;margin-top: 0px;">
                            <div class="premium-banner">
                                <div class="banner-content">
                                    <div class="left-section">
                                        <div class="crown-icon">
                                            <img src="../../../../assets/images/iptv-tutorial/Frame.png" alt="crown">
                                        </div>
                                        <div class="text-content">
                                            <p class="premium-t"> {{ "pageIPTVTutorial.BuyTrialPlanTitle1" | translate }}</p>
                                            <p class="premium-2"> {{ "pageIPTVTutorial.BuyTrialPlanTitle2" | translate }}</p>
                                        </div>
                                    </div>
                                    <button class="buy-button">
                                        {{ "pageIPTVTutorial.BuyNow" | translate }} →
                                        <!--                                        <span class="arrow">→</span>-->
                                    </button>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </app-spinner>
        </ng-template>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label id="AndroidTVBOX">
            {{ "pageIPTVTutorial.AndroidTVBOX" | translate }}

        </ng-template>
        <ng-template matTabContent>
            <app-spinner [loading]="loading">
                <div>
                    <article class='mat-typography'>
                        <div class='card'>
                            <p class="show-title"> {{ "pageIPTVTutorialAndroidTVBOX.title" | translate }}</p>
                            <p class="show-subtitle"> {{ "pageIPTVTutorialAndroidTVBOX.subtitle" | translate }}</p>
                            <p class="show-subtitle1"> {{ "pageIPTVTutorialAndroidTVBOX.subtitle1" | translate }}</p>
                            <div class='content'>
                                <p class="show-step1"> {{ "pageIPTVTutorialAndroidTVBOX.step1" | translate }} </p>
                                <p> <span class="title-l">1</span> {{ "pageIPTVTutorialAndroidTVBOX.title1" | translate }}</p>
                                <p class="img-container">
                                    <img src="../../../../assets/images/iptv-tutorial/AndroidTVBOX8.png" />
                                </p>
                                <p> <span class="title-l">2</span> {{ "pageIPTVTutorialAndroidTVBOX.title2" | translate }}</p>
                                <p class="img-container">
                                    <img src="../../../../assets/images/iptv-tutorial/AndroidTVBOX9.png" />
                                </p>
                                <p> <span class="title-l">3</span> {{ "pageIPTVTutorialAndroidTVBOX.title3" | translate }}</p>
                                <p class="img-container">
                                    <img src="../../../../assets/images/iptv-tutorial/AndroidTVBOX10.png" />
                                </p>
                                <p> <span class="title-l">4</span> {{ "pageIPTVTutorialAndroidTVBOX.title4" | translate }}</p>
                                <p class="img-container">
                                    <img src="../../../../assets/images/iptv-tutorial/AndroidTVBOX11.png" />
                                </p>
                                <p> <span class="title-l">5</span> {{ "pageIPTVTutorialAndroidTVBOX.title5" | translate }}</p>
                                <p class="img-container">
                                    <img src="../../../../assets/images/iptv-tutorial/AndroidTVBOX12.png" />
                                </p>
                                <p> <span class="title-l">6</span> {{ "pageIPTVTutorialAndroidTVBOX.title6" | translate }}</p>
                                <p class="img-container">
                                    <img src="../../../../assets/images/iptv-tutorial/AndroidTVBOX13.png" />
                                </p>
                                <p> <span class="title-l">7</span> {{ "pageIPTVTutorialAndroidTVBOX.title7" | translate }}</p>
                                <ul>
                                    <li>
                                        <p class="show-ct">{{ "pageIPTVTutorialAndroidTVBOX.title7-1" | translate }}</p>
                                    </li>
                                    <li>
                                        <p class="show-ct">{{ "pageIPTVTutorialAndroidTVBOX.title17-2" | translate }}</p>
                                    </li>
                                </ul>
                                <p class="show-line1"> <span class="title-l3"></span>{{ "pageIPTVTutorialAndroidTVBOX.title7-3" | translate }}</p>
                                <p class="img-container">
                                    <img src="../../../../assets/images/iptv-tutorial/AndroidTVBOX14.png" />
                                </p>

                                <p class="show-step1"> {{ "pageIPTVTutorialAndroidTVBOX.step2" | translate }} </p>
                                <p> <span class="title-l">1</span> {{ "pageIPTVTutorialAndroidTVBOX.2title1" | translate }}</p>
                                <p class="img-container">
                                    <img src="../../../../assets/images/iptv-tutorial/AndroidTVBOX15.png" />
                                </p>
                                <p> <span class="title-l">2</span> {{ "pageIPTVTutorialAndroidTVBOX.2title2" | translate }}</p>
                                <p class="img-container">
                                    <img src="../../../../assets/images/iptv-tutorial/AndroidTVBOX16.png" />
                                </p>
                                <p> <span class="title-l">3</span> {{ "pageIPTVTutorialAndroidTVBOX.2title3" | translate }}</p>
                                <p class="img-container">
                                    <img src="../../../../assets/images/iptv-tutorial/AndroidTVBOX17.png" />
                                </p>
                                <p> <span class="title-l">4</span> {{ "pageIPTVTutorialAndroidTVBOX.2title4" | translate }}</p>
                                <p class="img-container">
                                    <img src="../../../../assets/images/iptv-tutorial/AndroidTVBOX18.png" />
                                </p>
                                <p> <span class="title-l">5</span> {{ "pageIPTVTutorialAndroidTVBOX.2title5" | translate }}</p>
                                <p class="img-container">
                                    <img src="../../../../assets/images/iptv-tutorial/AndroidTVBOX19.png" />
                                </p>
                                <p> <span class="title-l">6</span> {{ "pageIPTVTutorialAndroidTVBOX.2title6" | translate }}</p>
                                <p class="img-container">
                                    <img src="../../../../assets/images/iptv-tutorial/AndroidTVBOX20.png" />
                                </p>
                                <p> <span class="title-l">7</span> {{ "pageIPTVTutorialAndroidTVBOX.2title7" | translate }}</p>
                                <ul>
                                    <li>
                                        <p class="show-ct">{{ "pageIPTVTutorialAndroidTVBOX.2title7-1" | translate }}</p>
                                    </li>
                                </ul>
                                <p class="img-container">
                                    <img src="../../../../assets/images/iptv-tutorial/AndroidTVBOX21.png" />
                                </p>

                                <p> <span class="title-l">8</span> {{ "pageIPTVTutorialAndroidTVBOX.2title8" | translate }}</p>
                                <ul>
                                    <li>
                                        <p class="show-ct">{{ "pageIPTVTutorialAndroidTVBOX.2title8-1" | translate }}</p>
                                    </li>
                                </ul>
                                <p class="img-container">
                                    <img src="../../../../assets/images/iptv-tutorial/AndroidTVBOX22.png" />
                                </p>

                                <p style="align-items: center;
        justify-content: center;text-align: center;"><span > {{ "pageIPTVTutorialAndroidTVBOX.2title9" | translate }}</span> </p>
                            </div>

                        </div>
                        <div class='card'>
                            <div class='content'>
                                <p class="show-step1"> {{ "pageIPTVTutorialAndroidTVBOX.3title" | translate }} </p>
                                <p> <span class="title-l">1</span> {{ "pageIPTVTutorialAndroidTVBOX.3title1" | translate }}</p>
                                <p> <span class="title-l">2</span> {{ "pageIPTVTutorialAndroidTVBOX.3title2" | translate }}</p>
                                <ul>
                                    <li>
                                        <p class="show-ct">{{ "pageIPTVTutorialAndroidTVBOX.3title2-1" | translate }}</p>
                                    </li>
                                </ul>
                                <p class="img-container">
                                    <img src="../../../../assets/images/iptv-tutorial/AndroidTVBOX23.png" />
                                </p>
                                <p> <span class="title-l">3</span> {{ "pageIPTVTutorialAndroidTVBOX.3title3" | translate }}</p>
                                <ul>
                                    <li>
                                        <p class="show-ct">{{ "pageIPTVTutorialAndroidTVBOX.3title3-1" | translate }}</p>
                                    </li>
                                </ul>
                                <p class="img-container">
                                    <img src="../../../../assets/images/iptv-tutorial/AndroidTVBOX24.png" />
                                </p>
                                <ul>
                                    <li>
                                        <p class="show-ct">{{ "pageIPTVTutorialAndroidTVBOX.3title3-2" | translate }}</p>
                                    </li>
                                </ul>
                                <p> <span class="title-l">4</span> {{ "pageIPTVTutorialAndroidTVBOX.3title4" | translate }}</p>
                                <ul>
                                    <li>
                                        <p class="show-ct">{{ "pageIPTVTutorialAndroidTVBOX.3title4-1" | translate }}</p>
                                    </li>
                                </ul>

                                <p> <span class="title-l">5</span> {{ "pageIPTVTutorialAndroidTVBOX.3title5" | translate }}</p>

                                <ul>
                                    <li>
                                        <p class="show-ct">{{ "pageIPTVTutorialAndroidTVBOX.3title5-1" | translate }}</p>
                                    </li>
                                    <li>
                                        <p class="show-ct">{{ "pageIPTVTutorialAndroidTVBOX.3title5-1" | translate }}</p>
                                    </li>
                                </ul>
                                <p> <span class="title-l">6</span> {{ "pageIPTVTutorialAndroidTVBOX.3title6" | translate }}</p>
                                <p class="img-container">
                                    <img src="../../../../assets/images/iptv-tutorial/AndroidTVBOX25.png" />
                                </p>
                                <p class="show-step1"> {{ "pageIPTVTutorialAndroidTVBOX.3title7" | translate }} </p>
                                <p class="show-line1"> <span class="title-l3"></span>{{ "pageIPTVTutorialAndroidTVBOX.3title7-1" | translate }}</p>
                                <p class="img-container">
                                    <img src="../../../../assets/images/iptv-tutorial/AndroidTVBOX26.png" />
                                </p>

                            </div>

                        </div>
                        <div class="card-sd" ><span>{{ "pageIPTVTutorial.BuyTrialPlan" | translate }}</span></div>
                        <div class='card' style="padding: 0px;margin-top: 0px;">
                            <div class="premium-banner">
                                <div class="banner-content">
                                    <div class="left-section">
                                        <div class="crown-icon">
                                            <img src="../../../../assets/images/iptv-tutorial/Frame.png" alt="crown">
                                        </div>
                                        <div class="text-content">
                                            <p class="premium-t"> {{ "pageIPTVTutorial.BuyTrialPlanTitle1" | translate }}</p>
                                            <p class="premium-2"> {{ "pageIPTVTutorial.BuyTrialPlanTitle2" | translate }}</p>
                                        </div>
                                    </div>
                                    <button class="buy-button">
                                        {{ "pageIPTVTutorial.BuyNow" | translate }} →
                                        <!--                                        <span class="arrow">→</span>-->
                                    </button>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </app-spinner>
        </ng-template>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label id="AndroidDevices">
            {{ "pageIPTVTutorial.AndroidDevices" | translate }}

        </ng-template>
        <ng-template matTabContent>
            <app-spinner [loading]="loading">
                <div>
                    <article class='mat-typography'>
                        <div class='card'>
                            <p class="show-title"> {{ "pageIPTVTutorialAndroidDevices.title" | translate }}</p>
                            <p class="show-subtitle"> {{ "pageIPTVTutorialAndroidDevices.subtitle" | translate }}</p>
                            <div class='content'>
                                <p> <span class="title-l">1</span> {{ "pageIPTVTutorialAndroidDevices.title1" | translate }}</p>

                                <p> <span class="title-l">2</span> {{ "pageIPTVTutorialAndroidDevices.title2" | translate }}</p>
                                <p class="img-container">
                                    <img src="../../../../assets/images/iptv-tutorial/AndroidDevices1.png" />
                                </p>
                                <p> <span class="title-l">3</span> {{ "pageIPTVTutorialAndroidDevices.title3" | translate }}</p>
                                <p> <span class="title-l">4</span> {{ "pageIPTVTutorialAndroidDevices.title4" | translate }}</p>

                                <p class="img-container">
                                    <img src="../../../../assets/images/iptv-tutorial/AndroidDevices2.png" />
                                </p>
                                <p> <span class="title-l">5</span><span>{{ "pageIPTVTutorialAndroidDevices.title5" | translate }}</span> </p>
                                <p class="img-container">
                                    <img src="../../../../assets/images/iptv-tutorial/AndroidDevices3.png" />
                                </p>
                                <p> <span class="title-l">6</span> {{ "pageIPTVTutorialAndroidDevices.title6" | translate }}</p>

                            </div>

                        </div>
                        <div class="card-sd" ><span>{{ "pageIPTVTutorial.BuyTrialPlan" | translate }}</span></div>
                        <div class='card' style="padding: 0px;margin-top: 0px;">
                            <div class="premium-banner">
                                <div class="banner-content">
                                    <div class="left-section">
                                        <div class="crown-icon">
                                            <img src="../../../../assets/images/iptv-tutorial/Frame.png" alt="crown">
                                        </div>
                                        <div class="text-content">
                                            <p class="premium-t"> {{ "pageIPTVTutorial.BuyTrialPlanTitle1" | translate }}</p>
                                            <p class="premium-2"> {{ "pageIPTVTutorial.BuyTrialPlanTitle2" | translate }}</p>
                                        </div>
                                    </div>
                                    <button class="buy-button">
                                        {{ "pageIPTVTutorial.BuyNow" | translate }} →
                                        <!--                                        <span class="arrow">→</span>-->
                                    </button>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </app-spinner>
        </ng-template>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label id="AppleSeries">
            {{ "pageIPTVTutorial.AppleSeries" | translate }}

        </ng-template>
        <ng-template matTabContent>
            <app-spinner [loading]="loading">
                <div>
                    <article class='mat-typography'>
                        <div class='card'>
                            <p class="show-title"> {{ "pageIPTVTutorialAppleSeries.title" | translate }}</p>
                            <p class="show-subtitle"> {{ "pageIPTVTutorialAppleSeries.subtitle" | translate }}</p>
                            <div class='content'>
                                <p> <span class="title-l">1</span> {{ "pageIPTVTutorialAppleSeries.title1" | translate }}</p>
                                <p class="show-line"> <span class="title-l3"></span><a class="show-bt" target="_blank" rel="noopener noreferrer" href="https://itunes.apple.com/us/app/iptv-smarters-player/id1383614816#?platform=ipad">https://itunes.apple.com/us/app/iptv-smarters-player/id1383614816#?platform=ipad</a></p>
                                <p class="img-container">
                                    <img src="../../../../assets/images/iptv-tutorial/AppleSeries1.png" />
                                </p>
                                <p> <span class="title-l">2</span> {{ "pageIPTVTutorialAppleSeries.title2" | translate }}</p>
                                <p> <span class="title-l">3</span> {{ "pageIPTVTutorialAppleSeries.title3" | translate }}</p>
                                <ul>
                                    <li>
                                        <p class="show-ct">{{ "pageIPTVTutorialAppleSeries.title3-1" | translate }}</p>
                                    </li>
                                    <li>
                                        <p class="show-ct">{{ "pageIPTVTutorialAppleSeries.title3-2" | translate }}</p>
                                    </li>
                                    <li>
                                        <p class="show-ct">{{ "pageIPTVTutorialAppleSeries.title3-3" | translate }}</p>
                                    </li>
                                </ul>
                                <p class="img-container">
                                    <img src="../../../../assets/images/iptv-tutorial/AppleSeries2.png" />
                                </p>
                                <p> <span class="title-l">4</span> {{ "pageIPTVTutorialAppleSeries.title4" | translate }}</p>

                                <p> <span class="title-l">5</span><span>{{ "pageIPTVTutorialAppleSeries.title5" | translate }}</span> </p>

                                <p class="img-container">
                                    <img src="../../../../assets/images/iptv-tutorial/AppleSeries3.png" />
                                </p>
                                <p> <span class="title-l">6</span> {{ "pageIPTVTutorialAppleSeries.title6" | translate }}</p>
                                <p class="img-container">
                                    <img src="../../../../assets/images/iptv-tutorial/AppleSeries4.png" />
                                </p>
                                <p> <span class="title-l">7</span> {{ "pageIPTVTutorialAppleSeries.title7" | translate }}</p>
                                <p class="img-container">
                                    <img src="../../../../assets/images/iptv-tutorial/AppleSeries5.png" />
                                </p>
                                <p> <span class="title-l">8</span> {{ "pageIPTVTutorialAppleSeries.title8" | translate }}</p>
                                <p class="img-container">
                                    <img src="../../../../assets/images/iptv-tutorial/AppleSeries6.png" />
                                </p>
                                <p> <span class="title-l">9</span> {{ "pageIPTVTutorialAppleSeries.title9" | translate }}</p>
                                <p class="img-container">
                                    <img src="../../../../assets/images/iptv-tutorial/AppleSeries7.png" />
                                </p>
                                <p> <span class="title-l">10</span> {{ "pageIPTVTutorialAppleSeries.title10" | translate }}</p>
                                <p style="align-items: center;
        justify-content: center;text-align: center;"><span > {{ "pageIPTVTutorialAppleSeries.title11" | translate }}</span> </p>

                            </div>

                        </div>
                        <div class="card-sd" ><span>{{ "pageIPTVTutorial.BuyTrialPlan" | translate }}</span></div>
                        <div class='card' style="padding: 0px;margin-top: 0px;">
                            <div class="premium-banner">
                                <div class="banner-content">
                                    <div class="left-section">
                                        <div class="crown-icon">
                                            <img src="../../../../assets/images/iptv-tutorial/Frame.png" alt="crown">
                                        </div>
                                        <div class="text-content">
                                            <p class="premium-t"> {{ "pageIPTVTutorial.BuyTrialPlanTitle1" | translate }}</p>
                                            <p class="premium-2"> {{ "pageIPTVTutorial.BuyTrialPlanTitle2" | translate }}</p>
                                        </div>
                                    </div>
                                    <button class="buy-button">
                                        {{ "pageIPTVTutorial.BuyNow" | translate }} →
                                        <!--                                        <span class="arrow">→</span>-->
                                    </button>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </app-spinner>
        </ng-template>
    </mat-tab>
    <mat-tab>
        <ng-template mat-tab-label id="Desktop">
            {{ "pageIPTVTutorial.Desktop" | translate }}

        </ng-template>
        <ng-template matTabContent>
            <app-spinner [loading]="loading">
                <div>
                    <article class='mat-typography'>
                        <div class='card'>
                            <p class="show-title"> {{ "pageIPTVTutorialDesktop.title" | translate }}</p>
                            <p class="show-subtitle"> {{ "pageIPTVTutorialDesktop.subtitle" | translate }}</p>
                            <div class='content'>
                                <p> <span class="title-l">1</span> {{ "pageIPTVTutorialDesktop.title1" | translate }}</p>
                                <p> <span class="title-l">2</span> {{ "pageIPTVTutorialDesktop.title2" | translate }}</p>
                                <p class="show-line"> <span class="title-l3"></span><a class="show-bt" target="_blank" rel="noopener noreferrer" href="https://www.iptvsmarters.com">https://www.iptvsmarters.com</a></p>
                                <p> <span class="title-l">3</span> {{ "pageIPTVTutorialDesktop.title3" | translate }}</p>
                                <p class="img-container">
                                    <img src="../../../../assets/images/iptv-tutorial/Desktop1.png" />
                                </p>
                                <p> <span class="title-l">4</span> {{ "pageIPTVTutorialDesktop.title4" | translate }}</p>
                                <p class="img-container">
                                    <img src="../../../../assets/images/iptv-tutorial/Desktop2.png" />
                                </p>

                                <p> <span class="title-l">5</span><span>{{ "pageIPTVTutorialDesktop.title5" | translate }}</span> </p>
                                <p> <span class="title-l">6</span> {{ "pageIPTVTutorialDesktop.title6" | translate }}</p>
                                <p> <span class="title-l">7</span> {{ "pageIPTVTutorialDesktop.title7" | translate }}</p>
                                <p> <span class="title-l">8</span> {{ "pageIPTVTutorialDesktop.title8" | translate }}</p>
                                <p> <span class="title-l">9</span> {{ "pageIPTVTutorialDesktop.title9" | translate }}</p>
                                <p> <span class="title-l">10</span> {{ "pageIPTVTutorialDesktop.title10" | translate }}</p>
                                <ul>
                                    <li>
                                        <p class="show-ct">{{ "pageIPTVTutorialDesktop.title10-1" | translate }}</p>
                                    </li>
                                    <li>
                                        <p class="show-ct">{{ "pageIPTVTutorialDesktop.title10-2" | translate }}</p>
                                    </li>
                                    <li>
                                        <p class="show-ct">{{ "pageIPTVTutorialDesktop.title10-3" | translate }}</p>
                                    </li>
                                </ul>
                                <p> <span class="title-l">11</span> {{ "pageIPTVTutorialDesktop.title11" | translate }}</p>
                                <p style="align-items: center;
        justify-content: center;text-align: center;"><span > {{ "pageIPTVTutorialDesktop.title12" | translate }}</span> </p>

                            </div>

                        </div>
                        <div class="card-sd" ><span>{{ "pageIPTVTutorial.BuyTrialPlan" | translate }}</span></div>
                        <div class='card' style="padding: 0px;margin-top: 0px;">
                            <div class="premium-banner">
                                <div class="banner-content">
                                    <div class="left-section">
                                        <div class="crown-icon">
                                            <img src="../../../../assets/images/iptv-tutorial/Frame.png" alt="crown">
                                        </div>
                                        <div class="text-content">
                                            <p class="premium-t"> {{ "pageIPTVTutorial.BuyTrialPlanTitle1" | translate }}</p>
                                            <p class="premium-2"> {{ "pageIPTVTutorial.BuyTrialPlanTitle2" | translate }}</p>
                                        </div>
                                    </div>
                                    <button class="buy-button">
                                        {{ "pageIPTVTutorial.BuyNow" | translate }} →
                                        <!--                                        <span class="arrow">→</span>-->
                                    </button>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </app-spinner>
        </ng-template>
    </mat-tab>

</mat-tab-group>
