<mat-card>
  <img
    src="../../../assets/images/contact-us/Icon-send-successful.png"
    alt="right"
    
  />
  <div class="title">{{ "pageWithdraw.ApplySuccess" | translate }}</div>
  <div class="content">
    {{ "pageWithdraw.ApplySuccessTips" | translate }}
  </div>
  <div class="buttons">
    <app-button 
      type="2"
      (click)="openExternalLink('https://lin.ee/0QILZj4')"
      height="60px"
      width="300px"
    >
      {{ "pageWithdraw.LineText" | translate }}
    </app-button>
    <app-button 
      type="1"  
      routerLink="/"
      height="60px"
      width="300px"
    >
      {{ "pageContactUs.Button2" | translate }}
    </app-button>
  </div>
</mat-card>
