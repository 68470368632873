<div class="list">
  <table>
    <tr>
      <th>{{ "pageWithdraw.WithdrawalTime" | translate }}</th>
      <th>{{ "pageWithdraw.WithdrawalMethod" | translate }}</th>
      <th>{{ "pageWithdraw.WithdrawalStatus" | translate }}</th>
      <th>{{ "pageWithdraw.Account" | translate }}</th>
      <th>{{ "pageWithdraw.Amount" | translate }}</th>
    </tr>
    <tr *ngFor="let data of records">
      <td><app-datetime [text]="data.created_time"></app-datetime></td>
        <td>{{ data.payment_method }} <span *ngIf="data.bankcode !== ''">/ {{ data.bankcode }}</span></td>
<!--      <td>-->
<!--        <app-payment-method [method]="data.payment_method"></app-payment-method>-->
<!--      </td>-->
      <td>
        <app-withdrawal-status [status]="data.status"></app-withdrawal-status>
      </td>
      <td>{{ data.account }}</td>
      <td>
        <app-money
          [price]="{ currency: data.currency, value: data.amount }"
          fontSize="16px"
        ></app-money>
      </td>
    </tr>
  </table>
  <div class="no-data" *ngIf="records?.length === 0 && !loading">
    <app-no-data
      src="../../../../assets/images/base/empty-data.webp"
      [width]="338"
      [height]="274"
      [margin]="0"
      >{{ "pageWithdraw.NoMore" | translate }}</app-no-data
    >
  </div>
</div>
