import { Component, Input, OnChanges } from '@angular/core';
import { Crisp } from 'crisp-sdk-web';
import { DialogService } from 'src/app/service/dialog.service';
import { EnterInvitationLinkModalComponent } from '../../subscription/enter-invitation-link-modal/enter-invitation-link-modal.component';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent implements OnChanges {
  text = '';
  @Input() isAuto = '';
  @Input() isIPTVAuto = '';
  @Input() data: any;

  constructor(private myDialog: DialogService) {}

  ngOnChanges() {
    if (this.data.bus.bus_account)
      this.text = this.data.bus.bus_account.username;
  }

  chat() {
    // Crisp.chat.open();
  }

  enter() {
    this.myDialog.open(EnterInvitationLinkModalComponent, {
      data: this.data,
      width: '700px',
      panelClass: 'order-dialog-container', // 添加自定义样式类
    });
  }
}
