import { Component } from '@angular/core';
import { AbstractNgModelComponent } from '../../base/abstract-ng-model/abstract-ng-model.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-korean-bank-select',
  templateUrl: './korean-bank-select.component.html',
  styleUrls: ['./korean-bank-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: KoreanBankSelectComponent,
    },
  ],
})
export class KoreanBankSelectComponent extends AbstractNgModelComponent {
  options: any[] = [
    { value: '카카오뱅크' },
    { value: 'NH농협' },
    { value: '기업은행' },
    { value: 'KB국민은행' },
    { value: '수협중앙회' },
    { value: '우리은행' },
    { value: 'SC제일은행' },
    { value: '신한은행' },
    { value: '부산은행' },
    { value: '광주은행' },
    { value: '우체국' },
    { value: 'KEB하나은행' },
    { value: '대구은행' },
    { value: '경남은행' }
  ];
  value = '';
}
