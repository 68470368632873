import { Component } from '@angular/core';
import {Subscription} from "rxjs";
import {ProductApiService} from "../../../service/product-api.service";
import {RegionService} from "../../../service/store/region.service";
import {MessageService} from "../../../service/message.service";
import {TranslateService} from "@ngx-translate/core";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: 'app-iptv-tutorial-content',
  templateUrl: './iptv-tutorial-content.component.html',
  styleUrls: ['./iptv-tutorial-content.component.scss']
})
export class IptvTutorialContentComponent {
  selectedIndex = 0; // 默认激活第一个标签页
  loading = false;
  platforms: any[] = [];
  region = '';
  language = '';
  textMessage: any;
  best_sellers: any[] = [];
  streaming: any[] = [];
  ai_tools: any[] = [];
  music: any[] = [];
  others: any[] = [];

  //regionSubscription: Subscription;

  constructor(
      private api: ProductApiService,
      private regionService: RegionService,
      private message: MessageService,
      private translateService: TranslateService,
      private route: ActivatedRoute,
      private activatedRoute :Router,
  ) {
    // this.regionSubscription = this.regionService.subscribe$.subscribe((r) => {
    //   this.findPlatforms();
    // });

    this.translateService.onLangChange.subscribe((text: any) => {
      //this.findPlatforms();
      this.translateService.get('textMessage').subscribe((text: string) => {
        this.textMessage = text;
      });
    });
  }

  ngOnInit(): void {
    this.translateService.get('textMessage').subscribe((text: string) => {
      this.textMessage = text;
    });
    //this.findPlatforms().then(() => {this.platformsClassify()});
    // console.log(this.platforms)
    // setTimeout(()=>{
    //   // 假设URL后缀是以#开头的锚点
    //   let u = this.activatedRoute.url
    //   const fragment = u.split('#')[1];
    //   if (fragment) {
    //     if (fragment === "Streaming") {
    //       this.selectedIndex = 1;
    //     } else if (fragment === "AITools") {
    //       this.selectedIndex = 2;
    //     } else if (fragment === "Music") {
    //       this.selectedIndex = 3;
    //     } else if (fragment === "Others") {
    //       this.selectedIndex = 4;
    //     } else if (fragment === "BestSellers") {
    //       this.selectedIndex = 0;
    //     }
    //     const element = document.getElementById("showPro");
    //     console.log(element)
    //     if (element) {
    //       element.scrollIntoView({ behavior: 'smooth',block: 'nearest' });
    //     }
    //     // const element = document.getElementById(fragment);
    //     // if (element) {
    //     //   element.scrollIntoView({ behavior: 'smooth' });
    //     // }
    //   }
    // },0)
  }

  async findPlatforms() {
    const language = localStorage.getItem('language') || '';
    const region = localStorage.getItem('region') || '';
    try {
      this.loading = true;
      const r = await this.api.findPlatforms(region, language);
      this.platforms = r.data;
    } catch (error) {
      this.message.error(this.textMessage.NetworkError);
    } finally {
      this.loading = false;
    }
  }

  async platformsClassify() {
    this.loading = true;
    // this.best_sellers =
    for(const data of this.platforms){
      if(data.platform_kinds.includes('best_seller')){
        this.best_sellers.push(data)
      }
    }
    for(const data of this.platforms){
      if(data.platform_kinds.includes('streaming')){
        this.streaming.push(data)
      }
    }
    for(const data of this.platforms){
      if(data.platform_kinds.includes('ai_tool')){
        this.ai_tools.push(data)
      }
    }
    for(const data of this.platforms){
      if(data.platform_kinds.includes('music')){
        this.music.push(data)
      }
    }
    for(const data of this.platforms){
      if(data.platform_kinds.includes('other')){
        this.others.push(data)
      }
    }

    this.loading = false;
  }
}
