import { Component } from '@angular/core';
import { AbstractNgModelComponent } from '../../base/abstract-ng-model/abstract-ng-model.component';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-tw-bank-select',
  templateUrl: './tw-bank-select.component.html',
  styleUrls: ['./tw-bank-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TwBankSelectComponent,
    },
  ],
})
export class TwBankSelectComponent extends AbstractNgModelComponent {
  options: any[] = [
    { value: '玉山银行（808）' },
    { value: '將來銀行（823）' },
    { value: '新光银行（103）' },
    { value: 'LINE BANK（824）' }
  ];
  value = '';
}
